import React from "react";
import { MantineProvider } from "@mantine/core";
import { appColors } from "../../themes";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <MantineProvider
      theme={{
        colors: {
          red: [
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            appColors.engineeringOrange,
            "#a61700",
            "#931400",
            "#811200",
          ],
        },
        headings: {
          sizes: {
            h1: {
              fontSize: "40px",
              fontWeight: 500,
            },
          },
        },
        black: appColors.charcoal,
        primaryColor: "red",
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      {children}
    </MantineProvider>
  );
}

export default Layout;

import React from "react";

import { Container, createStyles, Paper, Text, Title } from "@mantine/core";
import { appColors } from "../../themes";
import { useMediaQuery } from "@mantine/hooks";

interface SectionImageProps {
  placement: "left" | "right";
  src: string;
}

interface Props {
  title: string;
  description: string | React.ReactNode;
  image?: SectionImageProps;
  background?: "primary" | "secondary";
}

function Section(props: Props) {
  const { classes } = useStyles();

  const matches = useMediaQuery("(max-width: 520px)");

  const imageNode = (imageProps: SectionImageProps) => (
    <Paper className={classes.image}>
      <img src={imageProps.src} width={"100%"} height={matches ? 200 : 400} />
    </Paper>
  );

  return (
    <Container
      className={`${classes.wrapper} ${
        props.background === "secondary"
          ? classes.secondaryBackground
          : undefined
      }`}
    >
      {props.image &&
        (matches || props.image.placement === "left") &&
        imageNode(props.image)}
      <div className={classes.textContainer}>
        <Title order={matches ? 2 : 1} className={classes.title}>
          {props.title}
        </Title>
        <Text className={classes.description}>{props.description}</Text>
      </div>

      {props.image &&
        props.image.placement === "right" &&
        !matches &&
        imageNode(props.image)}
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 0,
    marginRight: 0,
    paddingBottom: 80,
    paddingLeft: 240,
    paddingRight: 240,
    paddingTop: 80,
    width: "100%",
    maxWidth: "100%",

    "@media (max-width: 520px)": {
      flexDirection: "column",

      paddingTop: 48,
      paddingBottom: 48,

      paddingLeft: "12%",
      paddingRight: "12%",
    },

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      columnGap: 16,
    },

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column",
      rowGap: 16,
    },
  },

  title: {
    lineHeight: 1,
    marginTop: theme.spacing.xl,
    fontWeight: 500,
  },

  description: {
    marginTop: theme.spacing.xs,
  },

  image: {
    maxWidth: "45%",
    justifyContent: "center",
    background: "transparent",

    "@media (max-width: 520px)": {
      maxWidth: "100%",
    },
  },

  secondaryBackground: {
    backgroundColor: appColors.offWhite,
  },

  textContainer: {
    display: "inline-flex",
    flexDirection: "column",
    rowGap: 32,
    maxWidth: "50%",

    "@media (max-width: 520px)": {
      marginTop: 24,
      maxWidth: "100%",
      rowGap: 16,
    },
  },
}));

export default Section;

import React from "react";
import { Button, ButtonProps } from "@mantine/core";

interface Props extends ButtonProps {}

function ContactButton(props: Props) {
  return (
    <Button
      {...props}
      h={50}
      component="a"
      href="tel:4167543752"
      id="contact-button"
    >
      Call: (416) 754-3752
    </Button>
  );
}

export default ContactButton;

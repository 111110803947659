import {
  Container,
  Text,
  Title,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import React from "react";

interface Props {
  title: string;
  description: string;
  phoneNumber: string;
}

function CallUs(props: Props) {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper} size={"100%"} w={"100%"}>
      <Title className={classes.title} size={40}>
        {props.title}
      </Title>
      <Text pt={32} size={16} className={classes.description}>
        {props.description}
      </Text>
      <UnstyledButton mt={64} component="a" href={`tel:${props.phoneNumber}`}>
        <Title className={classes.title} size={40}>
          {props.phoneNumber}
        </Title>
      </UnstyledButton>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    backgroundColor: theme.colors.red[6],
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingTop: 128,
    paddingBottom: 128,

    "@media (max-width: 755px)": {
      paddingTop: 80,
      paddingBottom: 60,
    },
  },

  title: {
    letterSpacing: -1,
    color: theme.white,
    textAlign: "center",

    "@media (max-width: 520px)": {
      fontSize: 28,
    },
  },

  description: {
    textAlign: "center",
    color: theme.white,
    "@media (max-width: 520px)": {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default CallUs;

import React from "react";
import {
  Burger,
  Container,
  createStyles,
  Group,
  Header,
  Title,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ContactButton } from "..";

interface NavHeaderProps {}

function NavHeader({}: NavHeaderProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();

  const matches = useMediaQuery("(max-width: 520px)");

  return (
    <Header
      height={matches ? 60 : 120}
      mx={0}
      w={"100%"}
      withBorder={false}
      sx={() => ({ border: 0 })}
    >
      <Container
        mx={0}
        maw={"100%"}
        w={"100%"}
        px={matches ? 48 : 128}
        className={classes.header}
      >
        <Title order={matches ? 4 : 2}>Agnijit Fire Protection</Title>
        <Group spacing={20} className={classes.links}>
          <ContactButton />
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
      </Container>
    </Header>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

export default NavHeader;

import React from "react";
import {
  ActionIcon,
  Container,
  createStyles,
  Group,
  Text,
} from "@mantine/core";
import { appColors } from "../../themes";

interface FooterSimpleProps {}

function FooterSimple(props: FooterSimpleProps) {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper} w={"100%"} size={"100%"}>
      <Text size="sm" c={"white"}>
        © Agnijit Fire Protection {new Date().getFullYear()}
      </Text>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 80,
  },

  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 128,
    paddingRight: 128,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    backgroundColor: appColors.charcoal,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
      rowGap: 16,
    },

    "@media (max-width: 520px)": {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
}));

export default FooterSimple;

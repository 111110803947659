import React from "react";
import { Container, createStyles, Image, Text, Title } from "@mantine/core";
import { ContactButton } from "..";

interface Props {
  title: string;
  description: string;
  imageSrc: string;
}

function Hero(props: Props) {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper} size={"100%"} w={"100%"} p={0}>
      <div className={classes.inner}>
        <Title className={classes.title} size={40}>
          {props.title}
        </Title>
        <Text size={16} className={classes.description}>
          {props.description}
        </Text>
        <div>
          <ContactButton />
        </div>
      </div>

      <div className={classes.image}>
        <img
          src={props.imageSrc}
          height={"100%"}
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
      </div>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.white,
    display: "inline-flex",
    flexDirection: "row",
  },

  image: {
    top: 0,
    bottom: 0,
    right: 0,
    minWidth: "10%",
    maxWidth: 700,

    "@media (max-width: 520px)": {
      display: "none",
    },
  },

  inner: {
    display: "inline-flex",
    flexDirection: "column",
    float: "left",
    paddingLeft: "12%",
    paddingRight: "12%",
    paddingTop: 125,
    paddingBottom: 125,
    rowGap: 32,
    minHeight: "60vh",

    "@media (max-width: 520px)": {
      paddingTop: 64,
      paddingBottom: 64,
    },
  },

  title: {
    letterSpacing: -1,

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },

  description: {
    "@media (max-width: 520px)": {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default Hero;

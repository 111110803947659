import * as React from "react";

import type { HeadFC, PageProps } from "gatsby";
import { AppShell, List, Text } from "@mantine/core";
import {
  AboutUs,
  CallUs,
  Footer,
  Hero,
  Layout,
  NavHeader,
  Section,
} from "../components";

const IndexPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <AppShell fixed={false} header={<NavHeader />} padding={0} w={"100%"}>
        <Hero
          title={`GTA's Leading Commercial \nFire Protection Services \nSince 1995`}
          description="Agnijit Fire Protection, a Canadian company, has offered fire protection solutions to businesses for more than a quarter-century. Your fire protection needs will be handled by our expert team of licensed and insured technicians."
          imageSrc="./extinguishers_silver.jpeg"
        />
        <AboutUs
          title="About Us"
          description="At Agnijit Fire Protection, our journey began in 1995 with a profound commitment to safeguarding lives and businesses from the ever-present threat of fire. For over a quarter-century, we have been diligently serving businesses in and around the GTA with an unwavering dedication to fire safety and protection."
        />
        <Section
          title="Installation"
          description={
            <List>
              <List.Item>
                Commericial kitchen fire suppression systems
                UL300/ORD-C1254.6-1995
              </List.Item>
              <List.Item>
                Industrial paint spray booth fire suppression system
              </List.Item>
              <List.Item>Kitchen exhaust hood as per NFPA-96</List.Item>
              <List.Item>Gas Piping</List.Item>
            </List>
          }
          image={{
            placement: "left",
            src: "./nozzle_closeup.jpeg",
          }}
        />
        <Section
          title="Inspection"
          description={
            <List>
              <List.Item>
                Annual inspections of portable fire extinguishers as per NFPA10
              </List.Item>
              <List.Item>
                Semi-annual inspection of fire supression system (commericial
                kitchen and industrial spray booth systems)
              </List.Item>
              <List.Item>Annual inspection of emergency lights</List.Item>
            </List>
          }
          background="secondary"
          image={{
            placement: "right",
            src: "./hood_nozzles.jpeg",
          }}
        />
        <Section
          title="More Services"
          description={
            <>
              <Text>
                Our skilled and certified technicians are proficient in both
                cutting-edge technology and time-tested techniques. This ensures
                not only your safety but also the proper and efficient
                functioning of your fire protection systems.
              </Text>
              <List mt={32}>
                <List.Item>Fire Suppression System</List.Item>
                <List.Item>Kitchen Exhaust Hood</List.Item>
                <List.Item>Inspection</List.Item>
                <List.Item>Installation of Fire Suppression System</List.Item>
                <List.Item>Mechanical drawings</List.Item>
                <List.Item>City approval</List.Item>
                <List.Item>Retrofit fire suppression system</List.Item>
                <List.Item>Balloon testing</List.Item>
                <List.Item>More</List.Item>
              </List>
              <Text mt={32}>
                Call us for more information on all our services
              </Text>
            </>
          }
          image={{
            placement: "left",
            src: "./extinguishers_horizontal.jpeg",
          }}
        />
        <CallUs
          title="Call Us"
          description="Feel free to reach out by using the number below"
          phoneNumber="(416) 754-3752"
        />
        <Footer />
      </AppShell>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <title>Agnijit Fire Protection | Commercial Fire Protection Services</title>
);

import { Container, Text, Title, createStyles } from "@mantine/core";
import React from "react";

interface Props {
  title: string;
  description: string;
}

function AboutUs(props: Props) {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper} size={"100%"} w={"100%"}>
      <Title className={classes.title} size={40}>
        {props.title}
      </Title>
      <Text size={16} className={classes.description}>
        {props.description}
      </Text>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    backgroundColor: theme.colors.red[6],
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingTop: 128,
    paddingBottom: 128,
    rowGap: 64,

    "@media (max-width: 755px)": {
      paddingTop: 80,
      paddingBottom: 60,

      paddingLeft: "12%",
      paddingRight: "12%",
    },
  },

  title: {
    letterSpacing: -1,
    color: theme.white,
    textAlign: "center",

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },

  description: {
    color: theme.white,
    "@media (max-width: 520px)": {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default AboutUs;
